import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromFeatures from '../reducers/features.reducer';
import { featuresKey } from '../reducers/features.reducer';

export const getFeatureState =
  createFeatureSelector<fromFeatures.State>(featuresKey);

export const getFeatures = createSelector(
  getFeatureState,
  fromFeatures.getFeatures,
);
